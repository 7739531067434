import _ from "lodash/fp";
import React from "react";
import { createSelector } from "reselect";
import { FormattedMessage } from "react-intl";
import DateTime from "@truedat/core/components/DateTime";
import { formatNumber } from "@truedat/core/services/format";
import RuleResultDecorator from "@truedat/dq/components/RuleResultDecorator";
import RuleImplementationLink from "@truedat/dq/components/RuleImplementationLink";
import RuleLink from "@truedat/dq/components/RuleLink";
import { Link } from "react-router-dom";
import { linkTo } from "@truedat/core/routes";
// import StructureLink from "../components/StructureLink";

const translateDecorator = id =>
  id ? <FormattedMessage id={id} defaultMessage={id} /> : null;

const resultTypeDecorator = (result, result_type) =>
  result_type === "errors_number" ? formatNumber(result) : `${result}%`;

export const defaultImplementationColumns = [
  {
    name: "implementation_key",
    fieldSelector: _.pick(["id", "implementation_key", "rule_id"]),
    fieldDecorator: RuleImplementationLink,
    sort: { name: "implementation_key.raw" },
    width: 2
  },
  {
    name: "structure_links_dataset",
    fieldSelector: ({ structure_links }) =>
      _.filter(
        structure_link => structure_link.link_type === "dataset",
        structure_links
      ),
    fieldDecorator: filtered_structure_links => {
      return _.map(
        ({ structure: { id, name } }) => (
          <div key={id}>
            <Link to={linkTo.STRUCTURE({ id })}>{name}</Link>
          </div>
        ),
        filtered_structure_links
      );
    },
    //sort: { name: "implementation_key.raw" },
    width: 2
  },
  // {
  //   name: "dataset",
  //   fieldSelector: _.getOr({}, "dataset.[0].structure"),
  //   fieldDecorator: StructureLink,
  //   width: 2
  // },
  {
    name: "description",
    fieldSelector: ruleImplementation =>
      ruleImplementation?.df_content?.description,
    textAlign: "center",
    width: 3
  },
  {
    name: "dq_dimension",
    fieldSelector: ruleImplementation =>
      ruleImplementation?.df_content?.dq_dimension,
    textAlign: "center",
    width: 1
  },
  {
    name: "impact",
    fieldSelector: ruleImplementation => ruleImplementation?.df_content?.impact,
    textAlign: "center",
    width: 1
  },

  {
    name: "rule",
    fieldSelector: ({ rule, rule_id: id }) => ({ id, name: rule?.name }),
    sort: { name: "rule.name.raw" },
    fieldDecorator: RuleLink,
    width: 2
  },
  {
    name: "status",
    fieldSelector: ({ status }) =>
      translateDecorator(`ruleImplementation.status.${status}`),
    sort: { name: "status" },
    width: 2
  },
  // {
  //   name: "business_concept",
  //   fieldSelector: _.path("current_business_concept_version.name"),
  //   sort: { name: "current_business_concept_version.name.raw" },
  //   width: 2
  // },
  {
    name: "last_execution_at",
    fieldSelector: ({ execution_result_info }) => ({
      value: execution_result_info?.date
    }),
    fieldDecorator: DateTime,
    sort: { name: "execution_result_info.date" },
    width: 2
  },
  // {
  //   name: "result_type",
  //   fieldDecorator: (value) =>
  //     _.isNil(value)
  //       ? null
  //       : translateDecorator(`ruleImplementations.props.result_type.${value}`),
  //   textAlign: "center",
  //   sort: { name: "result_type.raw" },
  //   width: 1,
  // },
  {
    name: "minimum",
    fieldSelector: _.pick(["minimum", "result_type"]),
    fieldDecorator: field =>
      resultTypeDecorator(field.minimum, field.result_type),
    sort: { name: "minimum" },
    textAlign: "right",
    width: 1
  },
  {
    name: "goal",
    fieldSelector: _.pick(["goal", "result_type"]),
    fieldDecorator: field => resultTypeDecorator(field.goal, field.result_type),
    sort: { name: "goal" },
    textAlign: "right",
    width: 1
  },
  {
    name: "result",
    fieldSelector: ruleImplementation => ({
      ruleResult: ruleImplementation?.execution_result_info,
      ruleImplementation
    }),
    fieldDecorator: RuleResultDecorator,
    textAlign: "center",
    sort: { name: "execution_result_info.result.sort" },
    width: 1
  },
  // {
  //   name: "inserted_at",
  //   fieldSelector: ({ inserted_at }) => ({ value: inserted_at }),
  //   fieldDecorator: DateTime,
  // },
  {
    name: "updated_at",
    fieldSelector: ({ updated_at }) => ({ value: updated_at }),
    fieldDecorator: DateTime
  }
];

const getColumns = state => state.ruleImplementationColumns;

export const getRuleImplementationColumns = createSelector(
  [getColumns],
  columns => _.defaultTo(defaultImplementationColumns)(columns)
);
