import _ from "lodash/fp";
import { defaultGrantsTableColumns } from "@truedat/dd/selectors";

const defaultColumnsWithoutMetadata = _.filter(
  _.negate(_.propEq("name", "metadata"))
)(defaultGrantsTableColumns);

const addedColumnaAprobador = [
  {
    name: "domain",
    fieldSelector: _.path("data_structure_version.domain.name")
  },
  {
    name: "approver",
    fieldSelector: _.path("detail.Aprobadores"),

    fieldDecorator: field => {
      console.log("VALUES", Object.values(field));
      console.log("JOIN", _.join(",", Object.values(field)));
      return _.flow(_.values, _.join(", "))(field);
    }
  }
];

const initialState = [
  ...defaultColumnsWithoutMetadata,
  ...addedColumnaAprobador
];

export const grantsTableColumns = (state = initialState) => state;
