import React from "react";
import { useIntl } from "react-intl";

const containerStyle = {
  width: "90%",
  maxWidth: "1200px",
  margin: "40px auto",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  gap: "20px",
  justifyItems: "center"
};

const cardStyle = {
  background: "white",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  padding: "30px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: "1 / 1",
  transition: " box-shadow 0.2s ease",
  maxHeight: "255px",
  transform: "scale(1)",
  zIndex: "0",
  transition: "all 0.2s ease",
  cursor: "pointer"
};

// modern and cool hover effect for each card
const cardHoverStyle = {
  boxShadow: "0 8px 16px rgba(5, 248, 175, 0.2)",
  transform: "scale(1.05)",
  zIndex: "1"
};

const iconStyle = {
  fontSize: "30px",
  marginBottom: "10px",
  color: "#037dba"
};

const titleStyle = {
  color: "#037dba",
  marginBottom: "8px"
};

const textStyle = {
  fontSize: "12px",
  color: "#555"
};

const SearchHomeAddon = () => {
  const { formatMessage } = useIntl();

  const [hoverIndex, setHoverIndex] = React.useState(null);

  const handleMouseEnter = index => setHoverIndex(index);
  const handleMouseLeave = () => setHoverIndex(null);

  const iconClassNameGlossary = `icon ${formatMessage({
    id: "sidemenu.glossary.icon",
    defaultMessage: "tag"
  })} large`;
  const iconClassNameCatalog = `icon ${formatMessage({
    id: "sidemenu.catalog.icon",
    defaultMessage: "block layout"
  })} large`;
  const iconClassNameGrants = `icon ${formatMessage({
    id: "sidemenu.grants.icon",
    defaultMessage: "shield"
  })} large`;
  const iconClassNameRules = `icon ${formatMessage({
    id: "sidemenu.rules.icon",
    defaultMessage: "check square"
  })} large`;

  return (
    <div style={containerStyle}>
      {[
        {
          href: "/concepts",
          icon: iconClassNameGlossary,
          titleId: "search.glossary",
          descId: "search.glossary_desc"
        },
        {
          href: "/structures",
          icon: iconClassNameCatalog,
          titleId: "search.data_catalog",
          descId: "search.data_catalog_desc"
        },
        {
          href: "/structuresGrantRequests",
          icon: iconClassNameGrants,
          titleId: "search.grants",
          descId: "search.grants_desc"
        },
        {
          href: "/rules",
          icon: iconClassNameRules,
          titleId: "search.data_quality",
          descId: "search.data_quality_desc"
        }
      ].map((card, index) => (
        <a
          key={index}
          href={card.href}
          style={
            hoverIndex === index
              ? { ...cardStyle, ...cardHoverStyle }
              : cardStyle
          }
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          <i className={card.icon} style={iconStyle}></i>
          <h2 style={titleStyle}>{formatMessage({ id: card.titleId })}</h2>
          <p style={textStyle}>{formatMessage({ id: card.descId })}</p>
        </a>
      ))}
    </div>
  );
};

export default SearchHomeAddon;
