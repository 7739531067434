import _ from "lodash/fp";
import {
  clearRuleImplementations,
  fetchRuleImplementations,
  searchRuleImplementations
} from "@truedat/dq/routines";

const initialState = [];
const pickFields = _.pick([
  "current_business_concept_version",
  "execution_result_info",
  "id",
  "implementation_key",
  "implementation_type",
  "system",
  "system_params",
  "rule",
  "rule_id",
  "results",
  "event_type",
  "event_inserted_at",
  "inserted_at",
  "updated_at",
  "deleted_at",
  "result_type",
  "structure_links",
  "linked_structures_ids",
  "minimum",
  "goal",
  "dataset",
  "valdations",
  "status",
  "domain_id",
  "df_name",
  "df_content"
]);

const ruleImplementations = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchRuleImplementations.TRIGGER:
      return initialState;
    case fetchRuleImplementations.SUCCESS:
      console.log("PAYLOAD NUEVO", payload.data);
      // FETCH_RULE_IMPLEMENTATIONS => NUEVO PAYLOAD
      return (
        _.sortBy("implementation_key")(_.map(pickFields)(payload.data)) || []
      );
    case searchRuleImplementations.SUCCESS:
      // SEARCH_RULE_IMPLEMENTATIONS => PAYLOAD
      return _.flow(_.path("data.data"), _.map(pickFields))(payload) || [];
    case clearRuleImplementations.TRIGGER:
      // CLEAR_RULE_IMPLEMENTATIONS => INICIALIZAR ESTADO
      return initialState;
    default:
      // RETURN ESTADO ACTUAL
      return state;
  }
};

export { ruleImplementations };
