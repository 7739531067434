import React, { useState } from "react";
import { Button, Header, Container, Image, Sidebar } from "semantic-ui-react";
import styles from "styles/navigation.less";
import { InitialUser } from "@truedat/auth/components";
import moeveLogo from "assets/moeve-logo-no-text.png";
import { FormattedMessage } from "react-intl";
import { Alert } from "@truedat/core/components";
import TopMenu from "../nav/TopMenu";

export const AppSetup = () => {
  const [showInitialUser, setShowInitialUser] = useState(false);

  return showInitialUser ? (
    <>
      <TopMenu />
      <Sidebar.Pushable>
        <Sidebar.Pusher>
          <Container className={styles.content} fluid>
            <Alert />
            <InitialUser />
          </Container>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  ) : (
    <>
      <Container className="initial-setup" textAlign="center">
        <Header
          inverted
          as="h1"
          icon
          textAlign="center"
          className="logo-header"
        >
          <Header.Content>
            <Image src={moeveLogo} className="logo-image" />
          </Header.Content>
        </Header>
        <p className="welcome">
          <span>
            <b>
              <FormattedMessage id="appSetup.welcome.message" />
            </b>
          </span>
        </p>
        <p className="configuration_message">
          <span>
            <FormattedMessage id="appSetup.configuration.message" />
          </span>
        </p>
        <Button primary onClick={() => setShowInitialUser(!showInitialUser)}>
          <FormattedMessage id="appSetup.begin.button" />
        </Button>
      </Container>
    </>
  );
};

export default AppSetup;
