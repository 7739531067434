import _ from "lodash/fp";
import React from "react";
// import { createSelector } from "reselect";
// import { FormattedMessage } from "react-intl";
import DateTime from "@truedat/core/components/DateTime";
// import { formatNumber } from "@truedat/core/services/format";
// import { ConceptsLinkDecorator } from "@truedat/bg/components";
// import RuleResultDecorator from "@truedat/dq/src/components/RuleResultDecorator";
// import RuleImplementationLink from "@truedat/dq/src/components/RuleImplementationLink";
// import RuleLink from "@truedat/dq/src/components/RuleLink";
import { defaultImplementationColumns } from "../selectors/getRuleImplementationColumns";
// import StructureLink from "../components/StructureLink";

const defaultImplementationColumnsRule = defaultImplementationColumns.filter(
  function (column) {
    return ![
      "description",
      "dq_dimension",
      "impact",
      "inserted_at",
      "updated_at",
      "last_execution_at",
      "structure_links_dataset"
    ].includes(column.name);
  }
);

const customImplementationColumns = [
  {
    name: "last_execution_at",
    fieldSelector: ({ execution_result_info }) => ({
      value: execution_result_info?.date
    }),
    fieldDecorator: DateTime,
    sort: { name: "execution_result_info.date" },
    width: 1,
    textAlign: "center"
  },
  {
    name: "inserted_at",
    fieldSelector: ({ inserted_at }) => ({ value: inserted_at }),
    fieldDecorator: DateTime,
    sort: { name: "inserted_at" },
    width: 1,
    textAlign: "center"
  },
  {
    name: "updated_at",
    fieldSelector: ({ updated_at }) => ({ value: updated_at }),
    fieldDecorator: DateTime,
    sort: { name: "updated_at" },
    width: 1,
    textAlign: "center"
  }
  // {
  //   name: "minimum",
  //   fieldSelector: _.pick(["minimum", "result_type"]),
  //   fieldDecorator: field =>
  //     resultTypeDecorator(field.minimum, field.result_type),
  //   sort: { name: "minimum" },
  //   textAlign: "right",
  //   width: 1
  // },
  // {
  //   name: "goal",
  //   fieldSelector: _.pick(["goal", "result_type"]),
  //   fieldDecorator: field => resultTypeDecorator(field.goal, field.result_type),
  //   sort: { name: "goal" },
  //   textAlign: "right",
  //   width: 1
  // },
];

export const ruleImplementationColumns = () => {
  return _.concat(
    defaultImplementationColumnsRule,
    customImplementationColumns
  );
};

// const translateDecorator = (id) =>
//   id ? <FormattedMessage id={id} defaultMessage={id} /> : null;

// const resultTypeDecorator = (result, result_type, resultType) =>
//   _.defaultTo(result_type)(resultType) === "errors_number"
//     ? formatNumber(result)
//     : `${result}%`;

// export const defaultImplementationColumns = [
//   {
//     name: "implementation_key",
//     fieldSelector: _.pick(["id", "implementation_key", "rule_id"]),
//     fieldDecorator: RuleImplementationLink,
//     sort: { name: "implementation_key.sort" },
//     width: 2,
//   },
//   {
//     name: "rule",
//     fieldSelector: ({ rule, rule_id: id }) => ({ id, name: rule?.name }),
//     sort: { name: "rule.name.raw" },
//     fieldDecorator: RuleLink,
//     width: 2,
//   },
//   {
//     name: "status",
//     fieldSelector: ({ status }) =>
//       translateDecorator(`ruleImplementation.status.${status}`),
//     sort: { name: "status" },
//     width: 2,
//   },
//   {
//     name: "business_concepts",
//     fieldSelector: _.path("concepts"),
//     fieldDecorator: ConceptsLinkDecorator,
//     width: 2,
//   },
//   {
//     name: "last_execution_at",
//     fieldSelector: ({ execution_result_info }) => ({
//       value: execution_result_info?.date,
//     }),
//     fieldDecorator: DateTime,
//     sort: { name: "execution_result_info.date" },
//     width: 2,
//   },
//   {
//     name: "result_type",
//     fieldDecorator: (value) =>
//       _.isNil(value)
//         ? null
//         : translateDecorator(`ruleImplementations.props.result_type.${value}`),
//     sort: { name: "result_type.raw" },
//     width: 2,
//   },
//   {
//     name: "minimum",
//     fieldSelector: _.pick(["minimum", "result_type"]),
//     fieldDecorator: (field) =>
//       resultTypeDecorator(field.minimum, field.result_type),
//     sort: { name: "minimum" },
//     textAlign: "right",
//     width: 1,
//   },
//   {
//     name: "goal",
//     fieldSelector: _.pick(["goal", "result_type"]),
//     fieldDecorator: (field) =>
//       resultTypeDecorator(field.goal, field.result_type),
//     sort: { name: "goal" },
//     textAlign: "right",
//     width: 1,
//   },
//   {
//     name: "result",
//     fieldSelector: (ruleImplementation) => ({
//       ruleResult: ruleImplementation?.execution_result_info,
//       ruleImplementation,
//     }),
//     fieldDecorator: RuleResultDecorator,
//     textAlign: "center",
//     sort: { name: "execution_result_info.result.sort" },
//     width: 2,
//   },
//   {
//     name: "inserted_at",
//     fieldSelector: ({ inserted_at }) => ({ value: inserted_at }),
//     fieldDecorator: DateTime,
//     sort: { name: "inserted_at" },
//   },
//   {
//     name: "updated_at",
//     fieldSelector: ({ updated_at }) => ({ value: updated_at }),
//     fieldDecorator: DateTime,
//     sort: { name: "updated_at" },
//   },
// ];
