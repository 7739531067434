import _ from "lodash/fp";
import { defaultGrantRequestsSearchTableColumns } from "@truedat/dd/src/selectors";
const initialState = [
  ...defaultGrantRequestsSearchTableColumns,
  {
    name: "data_structure_version.note.confidentiality",
    fieldSelector: _.path("data_structure_version.note.confidentiality")
  },
  {
    name: "metadata.Vigencia de la solicitud",
    fieldSelector: _.path("metadata.Vigencia de la solicitud")
  },
  {
    name: "metadata.Motivo de acceso",
    fieldSelector: _.path("metadata.Motivo de acceso")
  }
];

export const grantRequestsSearchColumns = (state = initialState) => state;
