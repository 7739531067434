import _ from "lodash/fp";
import React from "react";
import { Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { linkTo } from "@truedat/core/routes";

const iconDecorator = field => (field > 0 ? <Icon name="check circle" /> : "");

const translateDecorator = text => <FormattedMessage id={text} />;

const dateDecorator = date => (
  <Moment locale="es" date={date} format="YYYY-MM-DD HH:mm" />
);

const ConceptLink = concept =>
  concept?.id ? (
    <Link to={linkTo.CONCEPT_VERSION(concept)}>{concept?.name}</Link>
  ) : null;

const initialState = {
  concepts: [
    {
      name: "name",
      sort: { name: "name.raw" },
      width: 5,
      fieldDecorator: ConceptLink,
      fieldSelector: _.identity
    },
    {
      name: "domain",
      sort: { name: "domain.name.sort" },
      fieldSelector: _.path("domain.name"),
      width: 3
    },
    {
      name: "status",
      sort: { name: "status" },
      fieldDecorator: field => translateDecorator(`concepts.status.${field}`),
      width: 2
    },
    {
      name: "type_label",
      width: 2
    },
    {
      name: "rule_count",
      sort: { name: "rule_count" },
      fieldDecorator: iconDecorator,
      textAlign: "center",
      width: 1
    },
    {
      name: "link_count",
      sort: { name: "link_count" },
      fieldDecorator: iconDecorator,
      textAlign: "center",
      width: 1
    },
    {
      name: "last_change_at",
      sort: { name: "last_change_at" },
      fieldDecorator: dateDecorator,
      textAlign: "center",
      width: 2
    }
  ]
};

export const conceptsColumns = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
